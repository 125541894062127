import { Button as BaseButton, Typography } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";
import topBackground from "../../images/background.png";

const Contact = () => {
  return (
    <>
      <MainDiv>
        <ContactDiv>
          <BannerHeadlineText>お問い合わせ</BannerHeadlineText>
          <form
            className="formrun"
            action="https://form.run/api/v1/r/m22b2gtc0196z43it5lium2i"
            method="post"
          >
            <FormDiv>
              <Dl>
                <Label>お名前</Label>
              </Dl>
              <Dd>
                <Input
                  name="お名前"
                  type="text"
                  placeholder="山田 太郎"
                  required
                />
              </Dd>
            </FormDiv>
            <FormDiv>
              <Dl>
                <Label>フリガナ</Label>
              </Dl>
              <Dd>
                <Input
                  name="フリガナ"
                  type="text"
                  placeholder="ヤマダ タロウ"
                  required
                  pattern="(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*"
                />
              </Dd>
            </FormDiv>

            <FormDiv>
              <Dl>
                <Label>会社名</Label>
              </Dl>
              <Dd>
                <Input
                  name="会社名"
                  type="text"
                  placeholder="株式会社Lagopus"
                  required
                />
              </Dd>
            </FormDiv>

            <FormDiv>
              <Dl>
                <Label>電話番号</Label>
              </Dl>
              <Dd>
                <Input
                  name="お電話番号"
                  type="number"
                  placeholder="09012345678"
                  required
                />
              </Dd>
            </FormDiv>

            <FormDiv>
              <Dl>
                <Label>メールアドレス</Label>
              </Dl>
              <Dd>
                <Input
                  name="メールアドレス"
                  type="email"
                  data-formrun-type="email"
                  placeholder="test@test.com"
                  required
                />
              </Dd>
            </FormDiv>
            <FormDiv>
              <Dl>
                <Label>お問い合わせ項目</Label>
              </Dl>
              <Dd>
                <Select name="お問い合わせ項目" required>
                  <option>記事内容のお問い合わせ</option>
                  <option>掲載のご依頼</option>
                  <option>取材のご依頼</option>
                  <option>画面操作のご不明点</option>
                  <option>不具合のご連絡</option>
                  <option>その他お問い合わせ</option>
                </Select>
              </Dd>
            </FormDiv>

            <FormDiv>
              <Dl>
                <Label>お問い合わせ内容</Label>
              </Dl>
              <Dd>
                <Textarea name="お問い合わせ" required></Textarea>
              </Dd>
            </FormDiv>

            <Button type="submit">送信</Button>
          </form>
        </ContactDiv>
      </MainDiv>
    </>
  );
};

const MainDiv = styled.div`
  background-image: url(${topBackground});
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
`;

const ContactDiv = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 32px 0;
  flex-direction: column;
  align-items: center;
`;

const FormDiv = styled.div`
  margin-top: 16px;
`;

const Dl = styled.dl`
  margin: 0;
`;

const Dd = styled.dd`
  margin: 0;
`;

const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.xl2};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    border-radius: 10px;

    ::after {
      content: "*";
      color: red;
      margin-left: 0.15em;
    }
  `}
`;

const Input = styled.input`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    border: 1px solid #999;
    border-radius: 10px;
    width: 400px;
    height: 32px;

    //type numberの時の矢印をけす
    -moz-appearance: textfield;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `}
`;

const Select = styled.select`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    border-radius: 10px;
    width: 400px;
    height: 32px;
    border: 1px solid #999;
  `}
`;

const BannerHeadlineText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.xl4};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    border-radius: 10px;
  `}
`;

const Textarea = styled.textarea`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    resize: none;
    height: 200px;
    width: 400px;
    border: 1px solid #999;
    border-radius: 10px;
  `}
`;

const Button = styled(BaseButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.blue.primary};
    color: ${theme.colors.white.primary};
    font-size: ${theme.fonts.size.xl4};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    &:hover {
      background-color: ${theme.colors.blue.primary};
      color: ${theme.colors.white.primary};
      box-shadow: none;
    }
    width: 100%;
    margin-top: 16px;
    padding: 12px 64px;
    border-radius: 10px;
  `}
`;

export default Contact;
