import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import IndexJsonLd from "../components/IndexjsonLd";
import LoadingCircular from "../components/loadingCircular";
import { default as Seo } from "../layouts/head";
import Layout from "../layouts/pc";
import LayoutSP from "../layouts/sp";
import { default as ContactPC } from "../templates/pc/contact";
import ContactSP from "../templates/sp/contact";

const Contact = () => {
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(true);
  const title = "お問い合わせ";
  useEffect(() => {
    setTimeout(() => setLoading(false), 50);
  }, []);
  useEffect(() => {
    window.Formrun?.init(".formrun");
  }, []);
  return (
    <>
      <Seo title={title} />
      <IndexJsonLd title={title} />
      {loading ? (
        <LoadingCircular />
      ) : breakpoints.sm ? (
        <LayoutSP>
          <ContactSP />
        </LayoutSP>
      ) : (
        <Layout>
          <ContactPC />
        </Layout>
      )}
    </>
  );
};

export default Contact;
